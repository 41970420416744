import {useState,useEffect} from "react";
import { useQuery } from "@apollo/client";
import { RESOURCE_LITERATURE_DATA } from "../../utils/queries";
import config from "../../env/index.js";

function Literature() {

  const [resourceEp, setResourceEp] = useState();

  const {data, loading, error} = useQuery(RESOURCE_LITERATURE_DATA, {variables: {
    ticker: "SRHR",
    documentTypes: [
      {"type": "education"},
      {"type": "factsheet"},
      {"type": "distribution_schedule"},
      {"type": "presentation"},
      {"type": "shareholder_information"},
      {"type": "proxy_voting_records"},
      {"type": "nth_quarter_holdings", "friendlyName": "1st Quarter Holdings"},
      {"type": "nth_quarter_holdings", "friendlyName": "3rd Quarter Holdings"},
      {"type": "prospectus_sai"},
      {"type": "annual_report"},
      {"type": "semi_annual_report"},
      {"type": "statement_of_additional_information"},
      {"type": "prospectus"}
    ]
  }});

  const RegAdditionalData = [
    { title: 'N-PX Filing', url: 'https://www.sec.gov/Archives/edgar/data/1936157/000183988224028439/0001839882-24-028439-index.htm' },
  ]

  useEffect(() => {
    console.log("?>> data liter", data, config);
    let resourceEndpoint = config.graphQLEndpoint.match("staging") ? "https://staging-funds.paralel.com/download_resource/" : "https://api-funds.paralel.com/download_resource/";
    setResourceEp(resourceEndpoint);
  }, [data, config]);

  return (
    <div className="section__bg-2">
      <div className="container clearfix">
        <div className="col span_1_of_1 lg-full">
          <div id="literature" className="waypoint__subnav">
            <section>
              <h3 className="underline">Literature</h3>
              <div className="main_content">
                <div id="education" className="waypoint__subnav"></div>
                <div className="container clearfix">
                  <div className="col span_1_of_1 filterDrop">
                    <h3>Education</h3>
                  </div>
                  {data?.resources?.completeFundData?.slice(0,4).map(l => l.funds.map(f =>
                    <a
                      href={`${resourceEp}?id=${f.id}&ticker=${f.ticker}`}
                      target="_blank"
                      key={f.fileName}
                      className="docLink"
                    >
                      <span>{f.friendlyName}</span>
                    </a>))}
                </div>

                <div id="regulatory" className="waypoint__subnav"></div>
                <div className="container clearfix">
                  <div className="col span_1_of_1 filterDrop">
                    <h3>Regulatory</h3>
                  </div>
                  {data?.resources?.completeFundData?.slice(4).map(l => l.funds.map(f =>
                    <a
                      href={`${resourceEp}?id=${f.id}&ticker=${f.ticker}`}
                      target="_blank"
                      key={f.fileName}
                      className="docLink"
                    >
                      <span>{f.friendlyName}</span>
                    </a>))}
                    {RegAdditionalData?.map(f =>
                  <a
                    href={`${f.url}`}
                    target="_blank"
                    key={f.title}
                    className="docLink"
                  >
                    <span>{f.title}</span>
                  </a>)}
                </div>

                <div id="tax" className="waypoint__subnav"></div>
                <div className="container clearfix">
                  <div className="col span_1_of_1 filterDrop">
                    <h3>Tax Information</h3>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Literature;
